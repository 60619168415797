<!--  -->
<template>
  <div>
    <pHeader />
    <div
      class="banner"
      :style="{'background-image':'url('+require('@/assets/img/open_banner.png')+')'}"
    >
      <div class="txt-box">
        <h3>开源项目</h3>
        <p>所有的开源项目列表</p>
      </div>
    </div>
    <div class="content">
      <div class="inner">
        <div class="header-title">
          <div class="tit">开源项目索引</div>
        </div>
        <div class="project layout-flex">

          <div class="item">
            <div class="pro_tit">CastleFortress</div>
            <p class="pro_dec">CastleFortress 是一款能快速实现业务的开发框架，基于java语言，支持动态生成代码，减少基础编码工作，预设经典业务场景模板库，支持复制使用。完善的流程和逻辑，漂亮的界面，支持安卓、IOS、H5、小程序等移动端，极大降低开发成本。</p>
            <div class="icons layout-flex">
              <div class="i i1 layout-flex">
                <span class="b">license</span>
                <span class="a">Apache-2.0</span>
              </div>
              <div class="i i2 layout-flex">
                <a href='https://gitee.com/hcwdc/castle-fortress/stargazers'><img src='https://gitee.com/hcwdc/castle-fortress/badge/star.svg?theme=dark' alt='star' /></a>
              </div>
              <div class="i i3 layout-flex">
                <a href='https://gitee.com/hcwdc/castle-fortress/members'><img src='https://gitee.com/hcwdc/castle-fortress/badge/fork.svg?theme=dark' alt='fork' /></a>
              </div>
            </div>
            <div class="git layout-flex" @click="toBlank('https://gitee.com/hcwdc/castle-fortress')">
              Gitee
              <i class="iconfont pdxcalendar-right"></i>
            </div>
          </div>

          <div class="item">
            <div class="pro_tit">CastleFortress-ui</div>
            <p class="pro_dec">CastleFortress 的管理运营前端。</p>
            <div class="icons layout-flex">
              <div class="i i1 layout-flex">
                <span class="b">license</span>
                <span class="a">Apache-2.0</span>
              </div>
              <div class="i i2 layout-flex">
                <a href='https://gitee.com/hcwdc/castle-fortress-ui/stargazers'><img src='https://gitee.com/hcwdc/castle-fortress-ui/badge/star.svg?theme=dark' alt='star' /></a>
              </div>
              <div class="i i3 layout-flex">
                <a href='https://gitee.com/hcwdc/castle-fortress-ui/members'><img src='https://gitee.com/hcwdc/castle-fortress-ui/badge/fork.svg?theme=dark' alt='fork' /></a>
              </div>
            </div>
            <div class="git layout-flex" @click="toBlank('https://gitee.com/hcwdc/castle-fortress')">
              Gitee
              <i class="iconfont pdxcalendar-right"></i>
            </div>
          </div>

          <div class="item">
            <div class="pro_tit">docpreview</div>
            <p class="pro_dec">文件格式转换将多格式转换为pdf格式，转换为图片格式在进行预览展示。支持word 转 pdf、word 转图片等。</p>
            <div class="icons layout-flex">
              <div class="i i1 layout-flex">
                <span class="b">license</span>
                <span class="a">MIT</span>
              </div>
              <div class="i i2 layout-flex">
                <a href="https://gitee.com/hcwdc/docpreview/stargazers">
                  <img
                    src="https://gitee.com/hcwdc/docpreview/badge/star.svg?theme=dark"
                    alt="star"
                  />
                </a>
              </div>
              <div class="i i3 layout-flex">
                <a href="https://gitee.com/hcwdc/docpreview/members">
                  <img
                    src="https://gitee.com/hcwdc/docpreview/badge/fork.svg?theme=dark"
                    alt="fork"
                  />
                </a>
              </div>
            </div>
            <div class="git layout-flex" @click="toBlank('https://gitee.com/hcwdc/docpreview')">
              Gitee
              <i class="iconfont pdxcalendar-right"></i>
            </div>
          </div>

          <div class="item">
            <div class="pro_tit">OpenApi-SDK</div>
            <p class="pro_dec">API企业服务中台SDK文件！</p>
            <div class="icons layout-flex">
              <div class="i i1 layout-flex">
                <span class="b">license</span>
                <span class="a">MIT</span>
              </div>
              <div class="i i2 layout-flex">
                <a href="https://gitee.com/hcwdc/openapi-sdk/stargazers">
                  <img
                    src="https://gitee.com/hcwdc/openapi-sdk/badge/star.svg?theme=dark"
                    alt="star"
                  />
                </a>
              </div>
              <div class="i i3 layout-flex">
                <a href="https://gitee.com/hcwdc/openapi-sdk/members">
                  <img
                    src="https://gitee.com/hcwdc/openapi-sdk/badge/fork.svg?theme=dark"
                    alt="fork"
                  />
                </a>
              </div>
            </div>
            <div class="git layout-flex" @click="toBlank('https://gitee.com/hcwdc/openapi-sdk')">
              Gitee
              <i class="iconfont pdxcalendar-right"></i>
            </div>
          </div>

          <div class="item">
            <div class="pro_tit">Fortress-OpenApi-SDK</div>
            <p class="pro_dec">基础框架的对外开放API统一调用SDK文件！</p>
            <div class="icons layout-flex">
              <div class="i i1 layout-flex">
                <span class="b">license</span>
                <span class="a">MIT</span>
              </div>
              <div class="i i2 layout-flex">
                <a href="https://gitee.com/hcwdc/Fortress-OpenApi-SDK/stargazers">
                  <img
                    src="https://gitee.com/hcwdc/Fortress-OpenApi-SDK/badge/star.svg?theme=dark"
                    alt="star"
                  />
                </a>
              </div>
              <div class="i i3 layout-flex">
                <a href="https://gitee.com/hcwdc/Fortress-OpenApi-SDK/members">
                  <img
                    src="https://gitee.com/hcwdc/Fortress-OpenApi-SDK/badge/fork.svg?theme=dark"
                    alt="fork"
                  />
                </a>
              </div>
            </div>
            <div
              class="git layout-flex"
              @click="toBlank('https://gitee.com/hcwdc/Fortress-OpenApi-SDK')"
            >
              Gitee
              <i class="iconfont pdxcalendar-right"></i>
            </div>
          </div>
        </div>
        <!--        <pagination total="40" currentPage="1" pageSize="10"></pagination>-->
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
export default {
  data () {
    return {
      gitData: [
        {
          name: "",
          synopsis: "",
          stars: "",
          license: "",
          tag1: "tag1",
          tag2: "tag2",
          gitUrl: "",
        },
        {
          name: "",
          synopsis: "",
          stars: "23.3K",
          license: "MIT",
          tag1: "tag1",
          tag2: "tag2",
          gitUrl: "",
        },
        {
          name: "",
          synopsis: "",
          stars: "23.3K",
          license: "MIT",
          tag1: "tag1",
          tag2: "tag2",
          gitUrl: "https://gitee.com/hcwdc/Fortress-OpenApi-SDK",
        },
      ]
    };
  },

  components: {},

  computed: {},

  methods: {
    toBlank (url) {
      console.log(url)
      window.open(url, '_blank');
    }
  }
}

</script>
<style lang='scss' scoped>
.content {
  background: $bg;
  .inner {
    padding: 4rem 0;
    max-width: $minWidth;
    margin: 0 auto;
    .project {
      flex-wrap: wrap;
      .item {
        margin: 0 1rem 3rem;
        // margin-bottom: 3rem;
        width: 38rem;
        height: 25rem;
        padding: 3.5rem 3rem;
        background: #fff;
        border-radius: 0.4rem;

        .pro_tit {
          margin-bottom: 1rem;
          font-size: 2rem;
        }
        .pro_dec {
          @include show_line(2);
          margin-bottom: 1rem;
          font-size: 1.6rem;
          color: #2c3e50;
          line-height: 1.8;
        }
        .icons {
          margin-bottom: 2.5rem;
          font-size: 1.4rem;
          color: #fff;
          .i {
            border-radius: 0.2rem;
            overflow: hidden;
            height: 2.2rem;
            &:not(:last-child) {
              margin-right: 0.5rem;
            }
            .b,
            .a {
              padding: 0.3rem 0.5rem;
            }
          }
          .i1 {
            .b {
              background: #5b5b5b;
            }
            .a {
              background: #99c611;
            }
          }
          .i2 {
            .b {
              background: #c52c32;
              img {
                width: 1.4rem;
              }
            }
            .a {
              background: #424752;
            }
          }
          .i3 {
            .b {
              background: #5c5c5c;
            }
            .a {
              background: #4dc71f;
            }
          }
        }
        .git {
          justify-content: center;
          width: 8.5rem;
          height: 3.4rem;
          line-height: 3.4rem;
          font-size: 1.5rem;
          color: #376fff;
          border-radius: 0.5rem;
          border: 1px solid #376fff;
          cursor: pointer;
          i {
            margin-top: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
